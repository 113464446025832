import * as React from 'react';
import { get, intersection } from 'lodash';
import { RouteComponentProps } from 'react-router';
import {
    DefaultLoader,
    FourOhFour,
    LightspeedPostMessageType,
    LsTheme,
    useCredentials,
    useLoader,
    useMount,
    useTheme,
    usePageflowUrl,
    Credentials,
    SendToast,
} from 'ls-components';
import { css } from 'glamor';
import * as qs from 'querystring';
import { getDefaultRoute } from '../../util';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const ThemedProcessContainer = styled.div`
    background-color: ${({ theme }) => theme.backgroundColor} !important;
`;

export const IFrameContainer = ({ match, history }: RouteComponentProps) => {
    const parts = window.location.href.split('?');
    let queryStrRaw = '';
    let queryStrParsed = {};

    if (parts.length > 1) {
        try {
            queryStrRaw = parts.pop();
            queryStrParsed = qs.parse(queryStrRaw);
        } catch (e) {}
    }

    const iFrameEl = React.useRef<HTMLIFrameElement>(null);
    const { theme, setTheme } = useTheme();

    // get template page to load
    const template = get(match, 'params.template');
    const { run: getTemplate, busy, data, error, complete } = usePageflowUrl(template);

    // get data to render with
    const { credentials, redirectToLogin, setCredentials, logout } = useCredentials();
    const driver = get(credentials, 'driver');
    const url = get(data, 'output.url');
    const id = get(data, 'id');

    const postChildData = () => {
        if (iFrameEl.current) {
            iFrameEl.current.contentWindow.postMessage(
                {
                    type: 'lsPostChildData',
                    value: { credentials, id, templateId: template, theme },
                },
                url
            );
        }
    };

    const goToDefaultRoute = () => {
        history.push(getDefaultRoute(credentials.isAdmin, credentials.eulaRequired));
    };

    // set up listener for messages from the iframe
    const { completeLoading, addLoading } = useLoader();
    React.useEffect(
        iFrameListener(
            genHandleMessage(
                history.push,
                completeLoading,
                addLoading,
                setTheme,
                postChildData,
                redirectToLogin,
                setCredentials,
                logout,
                theme,
                redirectToLogin,
                goToDefaultRoute
            )
        ),
        [id, url, template, credentials.driver]
    );

    React.useEffect(() => {
        if (template && template.indexOf('id_token') === -1 && template.indexOf('access_token') === -1) {
            getTemplate({ data: { ...queryStrParsed }, key: JSON.stringify(queryStrParsed) });
        }
    }, [template]);

    // tell loading context to wait for us before displaying
    useMount(() => addLoading('LsHostedPage'));

    // tell loading context our data has fetched and page is ready
    React.useEffect(() => {
        if (data) {
            completeLoading('LsPage');
        }
        if (error) {
            const errMessage = error.message;
            const errErr = get(error, 'error');
            const errDataMessage = get(error, 'response.data.message');
            if (
                intersection(
                    ['Unauthorized', 'Request failed with status code 401'],
                    [errMessage, errDataMessage, errErr]
                ).length > 0
            ) {
                // token is expired or invalid
                logout();
            } else if (intersection(['EULA Required'], [errMessage, errDataMessage]).length > 0) {
                history.push('/eula');
            } else {
                // token is still good, they can't see that route or it doesn't exist
                // or thare was a server error
                goToDefaultRoute();
            }
        }
    }, [complete, url]);

    if (!complete || busy) {
        return <ThemedProcessContainer className="fill loading-wrapper" />;
    }

    return (
        <>
            {complete && url && driver ? (
                <ThemedProcessContainer className="fill loading-wrapper" style={{ display: 'flex' }}>
                    <iframe
                        ref={iFrameEl}
                        key={template}
                        id="process-frame"
                        src={`${url}?id=${id}&template=${template}&host=${getHostUrl()}&${queryStrRaw}`}
                        scrolling="yes"
                        style={{
                            border: 0,
                            height: '100%',
                            margin: 0,
                            padding: 0,
                            flex: 1,
                            width: '100%',
                            minWidth: '100%',
                            overflow: 'hidden',
                        }}
                    />
                    <div className={`loading-overlay hidden`}>
                        <div>
                            <DefaultLoader />
                        </div>
                    </div>
                </ThemedProcessContainer>
            ) : (
                <FourOhFour />
            )}
        </>
    );
};

const iFrameListener = (handleMessage: (message: any) => void) => () => {
    window.addEventListener('message', handleMessage, false);
    return () => {
        window.removeEventListener('message', handleMessage);
    };
};

type StringParamVoidFn = (param: string) => void;
type BooleanParamVoidFn = (param: boolean) => void;

const genHandleMessage = (
    push: StringParamVoidFn,
    completeLoading: StringParamVoidFn,
    addLoading: StringParamVoidFn,
    loadTheme: (theme: LsTheme) => void,
    postDataToChild: () => void,
    logout: () => void,
    loadCredentials: (credentials: Credentials) => void,
    logoutWithRedirect: (redirect?: boolean) => void,
    theme: LsTheme,
    redirectToLogin: () => void,
    goToDefaultRoute: () => void
) => (message: any) => {
    const type = get(message, 'data.type') as LightspeedPostMessageType;
    const value = get(message, 'data.value');

    if (type) {
        switch (type) {
            case 'lsLoadChildData':
                postDataToChild();
                break;
            case 'lsThemeChanged':
                loadTheme(value);
                break;
            case 'lsLoadingAdd':
                addLoading(value);
                break;
            case 'lsLoadingComplete':
                completeLoading(value);
                break;
            case 'hostedLoadingAdd':
                break;
            case 'hostedLoadingComplete':
                break;
            case 'redirect':
                push(value);
                break;
            case 'lsToast':
                themedToast(theme, value);
                break;
            case 'lsSetCredentials':
                loadCredentials(value);
                break;
            case 'lsTokenExpired':
                redirectToLogin();
                break;
            case 'unauthorizedPageflow':
                goToDefaultRoute();
                break;
            case 'logout':
                logoutWithRedirect();
                break;
        }
    }
};

const themedToast = (theme: LsTheme, toastEvent: SendToast['value']) => {
    const type = get(toastEvent, 'type');
    const content = get(toastEvent, 'content');

    let color = get(theme, 'infoToastColor');
    if (type) {
        switch (type) {
            case 'error':
                color = get(theme, 'errorToastColor');
                break;
            case 'success':
                color = get(theme, 'successToastColor');
                break;
            case 'warning':
                color = get(theme, 'warningToastColor');
                break;
        }
    }
    toast(content, {
        className: css({
            background: color,
            color: theme.fontBodyColor,
        }),
        progressClassName: css({
            background: 'rgba(0,0,0,0.1)',
        }),
    });
};

export const getHostUrl = () => {
    if (!window.location.origin) {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }
    return window.location.origin;
};
