import * as React from 'react';
import { HashRouter as Router, Route, Link, RouteComponentProps, withRouter, Switch, Redirect } from 'react-router-dom';
import { Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { get, includes, intersection, capitalize } from 'lodash';
import * as store from 'store2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormattedMessage, IntlProvider, addLocaleData } from 'react-intl';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faHomeAlt,
    faMobile,
    faAnalytics,
    faUserAlt,
    faSignOutAlt,
    faUserFriends,
    faSlidersHSquare,
    faIdCard,
    faHistory,
    faUserCog,
    faCreditCard,
} from '@fortawesome/pro-regular-svg-icons';
import * as en from 'react-intl/locale-data/en';
import * as es from 'react-intl/locale-data/es';
import * as fr from 'react-intl/locale-data/fr';
import * as de from 'react-intl/locale-data/de';

import { IFrameContainer } from './components';
import {
    ThemedNavBar,
    ThemedText,
    ThemedBrand,
    ThemedNav,
    ThemedNavItem,
    ThemedFooter,
    CustomizableTheme,
    CredentialsContainer,
    useCredentials,
    useMount,
    useTheme,
    LoadingContainer,
    useLoader,
    LoadingWrapper,
    VALIDATED,
    VALIDATING,
    NOT_VALIDATED,
    FrontendIdpFactory,
    useFormatMessage,
    InjectIntlContext,
    ThemedNavToggle,
    getLanguageMessages,
    SecondaryColorNavBorder,
    ThemedDropDownLink,
    isLightColor,
} from 'ls-components';
import { buildPageRoute, getDefaultRoute } from '../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/pro-solid-svg-icons';

addLocaleData([...en, ...es, ...fr, ...de]);
const { messages, language } = getLanguageMessages();

const ThemedLink = styled.a`
    ${ThemedText}
    margin-right: 30px;

    @media (max-width: 700px) {
        margin-right: 0px;
    }
`;

const TabIcon = ({ icon }: { icon: IconProp }) => (
    <FontAwesomeIcon icon={icon} style={{ marginRight: '8px' }} size="lg" />
);

const DropDownTabIcon = ({ icon, text }: { icon: IconProp; text: string }) => (
    <React.Fragment>
        <FontAwesomeIcon icon={icon} style={{ marginRight: '8px' }} size="lg" />
        {text}
    </React.Fragment>
);

const RoutedNav = withRouter(({ history, location, ...props }: RouteComponentProps) => {
    const template = get(location, 'pathname');
    const { theme, loadComplete } = useTheme();
    const { logout, validationState, credentials } = useCredentials();
    const { addLoading, completeLoading } = useLoader();
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const formatMessage = useFormatMessage();
    React.useEffect(() => {
        if (validationState === VALIDATED) {
            completeLoading('LsAuth');
        }
    }, [validationState]);
    React.useEffect(() => {
        if (loadComplete) {
            completeLoading('LsTheme');
        }
    }, [loadComplete]);
    React.useEffect(() => {
        setExpanded(false);
    }, [template]);
    const logo = get(theme, 'logo');
    const reportingEnabled = get(credentials, 'capabilities.reporting');
    const siteTitle = get(theme, 'siteTitle');
    const handleLogout = () => {
        logout(true);
    };
    const customerId = credentials.customerName;
    const customerTextLogo = get(theme, 'siteTitle', `${capitalize(customerId)}`);
    const customerTitle = `${capitalize(customerId)} Portal`;
    document.title = siteTitle ? siteTitle : customerTitle;
    const redirect = (route: string) => {
        if (route == history.location.pathname) return;
        addLoading('LsHostedPage');
        history.push(route);
    }
    return (
        <React.Fragment>
            <ThemedNavBar expand="md" expanded={expanded} onToggle={(newVal: boolean) => setExpanded(newVal)}>
                <ThemedBrand>
                    {logo ? <img src={logo} height={45} /> : <h4 style={{ marginTop: '.5rem' }}>{customerTextLogo}</h4>}
                </ThemedBrand>
                <ThemedNavToggle />
                <Navbar.Collapse id="basic-navbar-nav">
                    <ThemedNav className="mr-auto navbar-nav">
                        <ThemedNavItem>
                            <ThemedLink onClick={() => redirect(buildPageRoute(credentials.isAdmin ? 'admin-dashboard' : 'devices'))}>
                                <TabIcon icon={faHomeAlt} />
                                {formatMessage({ id: 'Home', defaultMessage: 'Home' })}
                            </ThemedLink>
                        </ThemedNavItem>
                        <ThemedNavItem>
                            <ThemedLink onClick={() => redirect(buildPageRoute('devices'))}>
                                <TabIcon icon={faMobile} />
                                {formatMessage({ id: 'Devices', defaultMessage: 'Devices' })}
                            </ThemedLink>
                        </ThemedNavItem>
                        {!credentials.isAdmin && (
                            <ThemedNavItem>
                                <ThemedLink onClick={() => redirect(buildPageRoute('billing-history'))}>
                                    <TabIcon icon={faHistory} />
                                    {formatMessage({ id: 'Billing History' })}
                                </ThemedLink>
                            </ThemedNavItem>
                        )}
                        {/* {intersection(['zipit-admin', 'admin', 'csmanager', 'csrep'], credentials.roles).length > 0 && (
                            <ThemedNavItem>
                                <ThemedLink to={buildPageRoute('billing')}>
                                    <TabIcon icon={faCreditCard} />
                                    {formatMessage({ id: 'Billing' })}
                                </ThemedLink>
                            </ThemedNavItem>
                        )} */}
                        {intersection(['zipit-admin', 'admin', 'csmanager', 'csrep'], credentials.roles).length > 0 && (
                            <ThemedNavItem>
                                <ThemedLink onClick={() => redirect(buildPageRoute('end-users'))}>
                                    <TabIcon icon={faUserFriends} />
                                    {formatMessage({ id: 'Customers', defaultMessage: 'Customers' })}
                                </ThemedLink>
                            </ThemedNavItem>
                        )}
                        {reportingEnabled &&
                            intersection(['zipit-admin', 'admin', 'csmanager'], credentials.roles).length > 0 && (
                                <ThemedNavItem>
                                    <ThemedLink onClick={() => redirect(buildPageRoute('embed-reporting'))}>
                                        <TabIcon icon={faAnalytics} />
                                        {formatMessage({ id: 'Reporting', defaultMessage: 'Reporting' })}
                                    </ThemedLink>
                                </ThemedNavItem>
                            )}
                        {intersection(['zipit-admin', 'admin', 'csmanager'], credentials.roles).length > 0 && (
                            <React.Fragment>
                                <ThemedDropDownLink
                                    title={
                                        <DropDownTabIcon
                                            icon={faUserCog}
                                            text={formatMessage({ id: 'Administration' })}
                                        />
                                    }
                                    id="basic-nav-dropdown"
                                >
                                    {intersection(['zipit-admin', 'admin', 'csmanager'], credentials.roles).length >
                                        0 && (
                                        <React.Fragment>
                                            <NavDropdown.Item
                                                onClick={() => redirect(buildPageRoute('admin-user-list'))}
                                            >
                                                <TabIcon icon={faIdCard} />
                                                {formatMessage({ id: 'Users', defaultMessage: 'Users' })}
                                            </NavDropdown.Item>
                                            <NavDropdown.Item
                                                onClick={() => redirect(buildPageRoute('admin-settings'))}
                                            >
                                                <TabIcon icon={faSlidersHSquare} />
                                                {formatMessage({ id: 'Themeing', defaultMessage: 'Themeing' })}
                                            </NavDropdown.Item>
                                            <NavDropdown.Item
                                                onClick={() => redirect(buildPageRoute('admin-settings-eula-list'))}
                                            >
                                                <TabIcon icon={faStickyNote} />
                                                {formatMessage({ id: 'EULAS', defaultMessage: 'EULAS' })}
                                            </NavDropdown.Item>
                                        </React.Fragment>
                                    )}
                                </ThemedDropDownLink>
                            </React.Fragment>
                        )}
                    </ThemedNav>
                    <ThemedNav className="navbar-nav">
                        <React.Fragment>
                                <ThemedDropDownLink
                                    title={
                                        <DropDownTabIcon
                                            icon={faUserAlt}
                                            text={credentials.username}
                                        />
                                    }
                                    id="basic-nav-dropdown"
                                >
                                <React.Fragment>
                                    <NavDropdown.Item
                                        onClick={() => redirect(buildPageRoute('user-settings'))}
                                    >
                                        <TabIcon icon={faIdCard} />
                                        {formatMessage({ id: 'User Settings', defaultMessage: 'User Settings' })}
                                    </NavDropdown.Item>
                                    {intersection(['end-user'], credentials.roles).length >
                                        0 && (
                                        <NavDropdown.Item
                                            onClick={() => redirect(buildPageRoute('user-billing-settings'))}
                                        >
                                            <TabIcon icon={faSlidersHSquare} />
                                            {formatMessage({ id: 'Billing Settings', defaultMessage: 'Billing Settings' })}
                                        </NavDropdown.Item>
                                    )}
                                </React.Fragment>
                            </ThemedDropDownLink>
                        </React.Fragment>
                        <ThemedNavItem onClick={handleLogout}>
                            <a>
                                <TabIcon icon={faSignOutAlt} />
                                <FormattedMessage id="Logout" defaultMessage="Logout" />
                            </a>
                        </ThemedNavItem>
                    </ThemedNav>
                </Navbar.Collapse>
            </ThemedNavBar>
            <SecondaryColorNavBorder />
        </React.Fragment>
    );
});

const Routes = () => {
    const { credentials } = useCredentials();
    return (
        <Switch>
            <EulaRoute path="/:template" component={IFrameContainer} />
            <Route render={() => <Redirect to={getDefaultRoute(credentials.isAdmin, credentials.eulaRequired)} />} />
        </Switch>
    );
};

const EulaRoute = ({ component: Component, ...rest }: any) => {
    const { credentials } = useCredentials();
    return (
        <Route
            {...rest}
            render={props => {
                return props.location.pathname != '/eula' && credentials.eulaRequired ? (
                    <Redirect to="eula" />
                ) : (
                    <Component {...props} />
                );
            }}
        />
    );
};

const Footer = () => {
    const version = process.env.VERSION;
    const {theme} = useTheme()
    return (
        <ThemedFooter>
            <Col xs={12} style={{ textAlign: 'center', paddingTop: '5px' }}>
                <FormattedMessage id="Powered By" defaultMessage="Powered By" />{' '}
                {isLightColor(theme.backgroundColor) ? 
                    <img src="https://zipitlightspeed.com/uploads/zipit_logo.png" height={25} /> :
                    <img src="https://zipitlightspeed.com/uploads/zipit_logo_white.png" height={25} />    
                }
            </Col>
            <Col xs={12} style={{ textAlign: 'center', fontSize: '8px', paddingBottom: '5px' }}>
                <FormattedMessage id="Version" defaultMessage="Version" /> {version}
            </Col>
        </ThemedFooter>
    );
};

const AppBody = () => (
    <>
        <RoutedNav />
        <Routes />
        <Footer />
    </>
);

const RoutedApp = withRouter(({ history, location }: RouteComponentProps) => {
    const [started, setStarted] = React.useState<boolean>(false);
    const { validationState, validateToken, redirectToLogin, credentials } = useCredentials();
    const { isLoading, addLoading } = useLoader();

    React.useEffect(() => {
        if (validationState === NOT_VALIDATED) {
            setStarted(false);
        }
        if (validationState === VALIDATING) {
            setStarted(true);
        }
        if (
            validationState === VALIDATED &&
            (includes(['/login', '/', '/eula'], location.pathname) ||
                location.pathname.indexOf('id_token') > -1 ||
                location.hash.indexOf('id_token') > -1)
        ) {
            history.push(getDefaultRoute(credentials.isAdmin, credentials.eulaRequired));
        }
        if (validationState == NOT_VALIDATED) {
            addLoading(['LsAuth', 'LsTheme', 'LsPage']);
        }
    }, [validationState, credentials]);

    useMount(() => {
        addLoading(['LsAuth', 'LsTheme', 'LsPage']);
        const driver = store.get('LsAuthDriver');
        if (driver) {
            const client = FrontendIdpFactory.createClient(driver);
            let creds;
            try {
                creds = client.getCredentials();
            } catch (e) {
                console.error(e);
                redirectToLogin();
            }
            validateToken(creds);
        } else {
            redirectToLogin();
        }
    });

    return (
        <Switch>
            {includes([VALIDATED, VALIDATING], validationState) && location.pathname !== '/login' && (
                <>
                    <LoadingWrapper isLoading={isLoading || !started}>
                        {validationState === VALIDATED && (
                            <CustomizableTheme>
                                <AppBody />
                            </CustomizableTheme>
                        )}
                    </LoadingWrapper>
                </>
            )}
            {validationState === NOT_VALIDATED && <Route render={() => <div />} />}
        </Switch>
    );
});

// setup react-toastify
toast.configure({
    position: toast.POSITION.BOTTOM_RIGHT,
});

const App = () => (
    <>
        <IntlProvider messages={messages} locale={language}>
            <InjectIntlContext>
                <LoadingContainer.Provider>
                    <CredentialsContainer.Provider basePath={process.env.API}>
                        <Router>
                            <RoutedApp />
                        </Router>
                    </CredentialsContainer.Provider>
                </LoadingContainer.Provider>
            </InjectIntlContext>
        </IntlProvider>
    </>
);

export default App;
